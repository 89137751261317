import Button from "@/components/Button";
import { ContactUsButton } from "@/components/ContactUsButton";
import { EciuPolygonIcon } from "@/components/EciuPolygons/EciuPolygon";
import { IconQuestionChat } from "@/components/Icons/IconQuestionChat";
import Link from "@/components/Link";
import Modal, { ModalProps } from "@/components/Modal/Modal";
import {
  INSAGroupMembers,
  MemberUniversityKeyOrInsaKey,
} from "@/components/OrganisationButton/memberUniversities";
import SelectUniversity, { UniversityKey } from "@/components/SelectUniversity";
import SystemMessage from "@/components/SystemMessage";
import { getColorCode } from "@/utils/colors";
import Cookies from "cookies";
import { CookieKeys } from "enums/CookieKeys";
import { withErrorReporting } from "middlewares/withErrorReporting";
import withSessionSsr from "middlewares/withSessionSsr";
import type {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  NextPage,
} from "next";
import { ReactNode, useState } from "react";
import { AccountAction, AccountActions } from "utils/routing";
import { useEABaseContext} from "../components/EABaseContext";

function getPageTitle(action: AccountAction) {
  switch (action) {
    case AccountAction.Claimdump:
      return "IDP Test - Claims dump";
    case AccountAction.ResetPassword:
      return "Reset password";
    case AccountAction.Signup:
      return "Sign up";
    case AccountAction.Link:
      return "Connect to university";
    default:
      return "";
  }
}

type ServerSideProps = {
  loginUrl: string;
  headerTitle: string;
  action: AccountAction;
};

async function getServerSidePropsImpl({
  res,
  req,
  query,
}: GetServerSidePropsContext): Promise<
  GetServerSidePropsResult<ServerSideProps>
> {
  /** Clear cookies to ensure clean state if user backs from IDP */
  const cookies = new Cookies(req, res);
  cookies.set(CookieKeys.ECIUCallbackTargetUrl, null);
  cookies.set(CookieKeys.ECIUActiveIDP, null);

  const user = req?.session?.user ?? null;

  const uniShortName = query["uniShortName"] as string | undefined;
  // Keeps track of current action in client. See useAction()
  let action =
    (query["action"] as AccountAction | undefined) ?? AccountAction.Signup;
  cookies.set(CookieKeys.ECIUActiveAction, action, { httpOnly: false });

  if (!AccountActions.includes(action)) {
    action = AccountAction.Signup;
  }

  if (action === AccountAction.Link && uniShortName) {
    return {
      redirect: {
        destination: `/api/auth/login?action=${action}&uniShortName=${uniShortName}`,
        permanent: false,
      },
    };
  }

  const isSignedIn = !!user;

  return isSignedIn
    ? {
        redirect: {
          destination: `/${action}`,
          permanent: false,
        },
      }
    : {
        props: {
          loginUrl: process.env.DXP_LOGIN_URL ?? "/",
          headerTitle: getPageTitle(action),
          action: action,
        },
      };
}

export const getServerSideProps = withSessionSsr(
  withErrorReporting(getServerSidePropsImpl)
);

function getHeaderRenderer(loginUrl: string) {
  const renderHeader = (
    props?: ModalProps,
    defaultRender?: (props?: ModalProps) => ReactNode
  ) => (
    <div className="flex items-baseline mb-2 flex-wrap gap-x-md ">
      {defaultRender && defaultRender(props)}
      <p>
        already signed up? <Link href={loginUrl}>Sign in</Link>
      </p>
    </div>
  );
  return renderHeader;
}

const Home: NextPage<ServerSideProps> = (props) => {
  const [selectedUniversity, setSelectedUniversity] = useState<
    UniversityKey | undefined
  >();

  const [showNotYetImplementedMessage, setShowNotYetImplementedMessage] =
    useState<boolean>(false);

  const sendToAuthenticationChallenge = (
    universityKey: MemberUniversityKeyOrInsaKey
  ) => {
    window.location.assign(
      `/api/auth/login?action=${props.action}&uniShortName=${universityKey}`
    );
  };

  const back = () => {
    setSelectedUniversity(undefined);
  };

  const isINSAGroupOrMemberSelected =
    selectedUniversity === "insa" ||
    INSAGroupMembers.some((x) => x.shortName === selectedUniversity);

  const isGroupExpanded = isINSAGroupOrMemberSelected;
  const base = useEABaseContext();
  const SUPPORT_SETTING_SIGNUP = "I need help to create my account";
  return (
    <Modal
      header={props.headerTitle}
      onRenderHeader={
        props.action === AccountAction.Signup
          ? getHeaderRenderer(props.loginUrl)
          : undefined
      }
      onRenderAction={() => (
        <>
          <div className="flex mr-auto flex-row py-2">
            <IconQuestionChat className="w-10 h-10 p-1"/>
            <div className="flex flex-col px-2">
              <span className="font-bold">Need help signing up?</span>
              <span>Reach out to us via this&nbsp;
                <ContactUsButton 
                  className="w-fit" 
                  text=" contact form" 
                  formProps={{
                      presets: {
                        contactFormSetting: base.contactFormSettings.find(x => x.name == SUPPORT_SETTING_SIGNUP)
                      },
                  }}  
                  memberUniversities={base.memberUniversities}
                  contactFormSettings={base.contactFormSettings}
                  learningOppNames={base.learningOppNames}
                />
              </span>
            </div>
          </div>
          {isGroupExpanded && (
            <Button kind="secondary" onClick={back}>
              BACK
            </Button>
          )}
        </>
      )}
    >
      <div className="flex items-center gap-xs mb-lg">
        <EciuPolygonIcon fillColor={getColorCode("yellow")} />
        <p className="text-style-overline">Choose your home university</p>
      </div>

      <SelectUniversity
        allowGroupExpansion
        selectedUniversity={selectedUniversity}
        onUniversitySelected={setSelectedUniversity}
        onActionClick={sendToAuthenticationChallenge}
      />

      

      {showNotYetImplementedMessage && (
        <SystemMessage kind="info" className="my-xl">
          There is no IDP configuration for this university yet. Do not worry,
          the work is ongoing.
        </SystemMessage>
      )}
    </Modal>
  );
};

export default Home;
