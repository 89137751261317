import { SVGProps } from "react";

export function IconQuestionChat(props: SVGProps<SVGSVGElement>) {
  return (
    <svg 
      width="1em" 
      height="1em" 
      viewBox="0 0 32 32" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >      
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M16.4519 10.0529C15.2288 9.83272 13.9831 10.2628 13.393 11.433C13.1443 11.9261 12.543 12.1243 12.0498 11.8756C11.5567 11.6269 11.3585 11.0256 11.6072 10.5324C12.6944 8.37636 14.9487 7.75015 16.8062 8.08449C18.6159 8.41021 20.5001 9.76109 20.5001 11.9869C20.5001 12.9764 20.215 13.7436 19.7535 14.3582C19.3179 14.938 18.755 15.3361 18.3234 15.6365C17.3795 16.2936 17.0042 16.5705 17.0042 17.3216C17.0042 17.8739 16.5565 18.3216 16.0042 18.3216C15.4519 18.3216 15.0042 17.8739 15.0042 17.3216C15.0042 15.4928 16.2304 14.6493 17.0437 14.0897C17.0909 14.0573 17.1368 14.0257 17.1809 13.995C17.6233 13.6871 17.9343 13.45 18.1543 13.1571C18.3482 12.8988 18.5001 12.5558 18.5001 11.9869C18.5001 11.096 17.7229 10.2816 16.4519 10.0529Z" 
        fill="currentColor"
      />
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M13.84 22.0023C13.84 20.8986 14.7332 20 15.84 20C16.9467 20 17.84 20.8986 17.84 22.0023C17.84 23.106 16.9467 24.0046 15.84 24.0046C14.7332 24.0046 13.84 23.106 13.84 22.0023ZM15.8393 22C15.8387 22.0001 15.8389 22 15.84 22C15.8411 22 15.8412 22.0001 15.8407 22C15.8407 21.9999 15.8407 21.9999 15.8408 21.9999C15.8416 21.999 15.8419 21.9991 15.841 21.9994L15.84 21.9998L15.8389 21.9994C15.8385 21.9993 15.8384 21.9992 15.8384 21.9992C15.8384 21.9992 15.8387 21.9994 15.8392 21.9999C15.8392 21.9999 15.8392 21.9999 15.8393 22ZM15.84 22.0048L15.841 22.0052C15.8414 22.0053 15.8415 22.0054 15.8416 22.0054C15.8416 22.0054 15.8413 22.0053 15.8408 22.0048C15.8407 22.0047 15.8407 22.0047 15.8407 22.0046" 
        fill="currentColor"
      />
      <path 
        d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 17.9658 3.43631 19.8296 4.21736 21.5L2 29L9.93223 27.5C11.7436 28.4577 13.8085 29 16 29Z" 
        stroke="currentColor"
        strokeWidth="2" 
        strokeLinejoin="round"
      />
    </svg>
  );
}